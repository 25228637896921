import React, {useState, useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faShoppingCart, faUser, faAngleRight, faPhone, faBars, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from "react-router-dom";
import env from "../../config";
import FormatterCoin from "../formatterCoin";
import api from '../../services/api';

const MenuDepartamentos = (props) => {
    const [departamentos, setDepartamentos] = useState([]);
    const [departamento, setDepartamento] = useState(null);
    
    const handleDepartamento = (props) => {
        setDepartamento(props);
    }

    useEffect(()=>{    
        api.get("/departamentos")         
        .then(function (response) {
            setDepartamentos(response.data.data);                                      
        }); 
    }, []);


    return (
        <div className="menu-drop animate__animated  animate__fadeInLeft animate__faster">
            <ul className="menu-dep-content">
                {departamentos && departamentos.map( (dep, i) => {
                    return (
                        <li onMouseEnter={()=>{handleDepartamento(dep)}}>
                            {dep.nome} <FontAwesomeIcon icon={faAngleRight} className="dep-angle-icon" />
                        </li>
                    );
                    })}
            </ul>
            
            <ul className="menu-cat">
                {departamento &&
                    <span><b><a href={"/produtos/"+departamento.url}>{departamento.nome}</a></b></span>
                }
                {(departamento && departamento.categorias) && departamento.categorias.map( (cat, i) => {
                    return (
                        <li>                            
                            <a href={"/produtos/"+ departamento.url + "/" +cat.url}>{cat.nome}</a>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
}

const Header = (props) => {
    const history = useHistory();
    const [busca, setBusca] = useState(null);

    const handleSearche = (event) => {        
        history.push("/produtos/"+busca);
    }

    return (        
        <header id="main-header">
            <Container>
                <Row className="main-header-top">
                    <Col xs={12} md={4} className="logo-div">                    
                    <Link to="/">
                        <h1>Infor Variedades</h1>
                    </Link>
                    </Col>
                    <Col xs={12} md={4}>
                        <div className="search-bar"> 
                            <form onSubmit={handleSearche}>              
                                <input type="text" onChange={(param)=>{setBusca(param.target.value)}}
                                    id="search" name="search" placeholder="O que você está procurando?" spellCheck="false" autoComplete="off" dir="auto" nm-autocomplete="off"
                                 />
                                <button type="submit" value="Buscar" id="btnOK" className="search-button">
                                    <FontAwesomeIcon icon={faSearch} />    
                                </button>
                            </form>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className="main-header-icons">
                        <div className="main-header-user">                            
                            <FontAwesomeIcon icon={faUser} />
                            <span>              
                                {(!props.usuario || props.usuario.error) &&
                                    <>
                                        Bem Vindo!<br/>                
                                        <Link to={"/login"}>Entrar</Link> | 
                                        <Link to={"/cadastro"}> Cadastrar</Link>
                                    </>
                                }
                                {(props.usuario && !props.usuario.error) &&
                                    <>
                                        <Link to="/usuario">{props.usuario.nome}</Link><br/>
                                        <Link to={"/usuario/" + props.usuario.id + "/pedidos"}>Meus Pedidos</Link>
                                    </>
                                }
                            </span>
                        </div>
                        <div className="shopping-cart-icon">
                            <Link to={"/pedido/" + props.pedido_carrinho.id}>
                                <FontAwesomeIcon icon={faShoppingCart} />                                
                                <span className="sale-count-itens">{props.pedido_carrinho.qtd_itens}</span>                            
                                <div className="shopping-cart-info">
                                    <span>{props.pedido_carrinho.qtd_itens} Itens</span>
                                    <br/>
                                    <strong>Total R$ <FormatterCoin valor={props.pedido_carrinho.valor_total}/></strong>
                                    <br/>
                                    <small>Ver Carrinho</small>
                                </div>
                            </Link>
                        </div>
                    </Col>
                </Row>                
            </Container>
            <div className="nav-bar-box">
                <Container>
                    <Row>
                        <nav className="navbar">
                            <ul>
                                <li className="menu-dep">
                                    Departamentos <FontAwesomeIcon icon={faBars} /> 
                                    <MenuDepartamentos />
                                </li>
                                <li>
                                    <a href="/produtos/lehmox">Lehmox</a>
                            </li>
                                <li>
                                    <a href="/produtos/promocao" >Promoções</a>
                            </li>
                                <li>
                                    <a href="/produtos/nvd" >Novidades</a>
                            </li>
                            </ul>
                        </nav>
                    </Row>
                </Container>
            </div>
            <div className="sombra-menu"></div>
        </header>                
    )
}
 
export default Header;