import React, {Component, useLocation, useEffect, useState} from 'react';
import { Container, Card, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import {useParams, Link} from "react-router-dom";
import env from "../../config";
import "./styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';
import api from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VendaModalAddProduto from '../../components/vendaModalAddProduto';

const buscarDep = (departamentos, departamento) => {
    let depart_find;

    departamentos.map((dep, i)=>{
        if (dep.url == departamento) {
            depart_find = dep
        }
    });

    return depart_find;
}

const buscarCat = (depart_find, categoria) => {
    let categ_find;

    if (depart_find.categorias) {
        depart_find.categorias.map((cat, i) => {
            if ( cat.url == categoria ) {
                categ_find = cat
            }
        });
    }

    return categ_find;
}

const NavLink = (props) => {
    let { departamento, categoria, subcategoria } = useParams();

    let depart_find = buscarDep(props.departamentos, departamento);

    if (!depart_find){
        return <></>
    }

    let categ_find = buscarCat(depart_find, categoria);

    return (
        <div className="products-cat-link">
            { depart_find &&
                <Link to={"/produtos/"+depart_find.url}>{depart_find.nome} </Link>                 
            }
            {categ_find && 
                <>
                <FontAwesomeIcon icon={faChevronRight} /> 
                <Link to={"/produtos/" + depart_find.url + "/" + categ_find.url} > {categ_find.nome} </Link> 
                </>
            }
        </div>
    )
}

const PaginateProduct = (props) => {
    return (   
        <div className="pagination-container">
            <ReactPaginate
                previousLabel={'Anterior'}
                nextLabel={'Próximo'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={props.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={props.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
            />        
        </div>     
    );
}

const coin = (cell) => {
    let formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });
  
    return (
      <span>{formatter.format(parseFloat(cell).toFixed(2))}</span>
    );
}

const ProductsList = (props) => {
    let { departamento, categoria, subcategoria } = useParams();
    
    const [show, setShow] = useState(false);
    const [produtoAdd, setProdutoAdd] = useState({});

    useEffect(() => {
        props.handlePageChange();
    }, [departamento, categoria]);

    const handleComprar = (prod) => {
        let text;

        setProdutoAdd(prod);
        
        text = "*CONSULTA INFOR*\n"+ prod.nome + "\nQuantidade:" ;                    
        
        if (props.usuario.id){            
            setShow(true);
        }
        else{
            let url = "https://api.whatsapp.com/send?1=pt_BR&phone=5588998457793&text=" + encodeURIComponent(text);
            window.open(url);
        }
    }

    return (
        <div className="cards-container">
            {props.produtos.length == 0 &&
                <h3 className="no-products">Nenhum Produto Encontrado</h3>
            }
            {props.produtos && props.produtos.map( (prod, i) =>{ 
                return (
                    
                    <Card style={{ opacity: (prod.disponivel_venda ? 1 : 0.4) }}>
                        <Link to={"/produto/" + prod.id}>
                        {prod.produtos_fotos &&
                            <div className="card-box-img">
                                <img className="products-card-image" src={env.url_files + prod.produtos_fotos.id +"-"+ prod.produtos_fotos.filename } />
                            </div>
                        }
                        </Link>
                        <Card.Body>                            
                            <Card.Title>                                
                                {prod.disponivel_venda && (props.usuario == null || props.usuario.error) &&
                                    <Link to={"/produto/" + prod.id}>
                                        <span className="tag-varejo">Entre no sistema</span>
                                    </Link>
                                }
                                {prod.disponivel_venda && props.usuario && !props.usuario.error &&
                                    <Link to={"/produto/" + prod.id}>
                                        <span className="produto-preco"> {coin(prod.preco_atacado)} </span>
                                        <span className="tag-atacado">Atacado</span>
                                    </Link>
                                }
                                {!prod.disponivel_venda &&
                                    <Link to={"/produto/" + prod.id}>
                                        <span className="tag-falta">Em falta</span>
                                    </Link>
                                }                            
                            </Card.Title>
                            <Card.Text>
                            <Link to={"/produto/" + prod.id}> {prod.nome} </Link>                            
                            </Card.Text>
                            {prod.disponivel_venda && props.usuario && props.usuario.id &&
                                <div className="card-button-box">
                                    <button className="btn-comprar" onClick={()=>{handleComprar(prod)}}>Comprar</button>
                                </div>
                            }

                            {prod.disponivel_venda && props.usuario && !props.usuario.id &&
                                <div className="card-button-box">
                                    <button className="btn-comprar" onClick={()=>{handleComprar(prod)}}>Consultar Preço</button>
                                </div>
                            }
                        </Card.Body>
                </Card>)      
            })
            }
            <VendaModalAddProduto show={show} produto={produtoAdd} handleClose={()=>{setShow(false)}} pedido_carrinho={props.pedido_carrinho} usuario={props.usuario} buscarPedidoCarrinho={props.buscarPedidoCarrinho}/>
            <ToastContainer />
        </div>        
    )
}

class Products extends Component{
    constructor (props) {        
        super(props);
        this.state = {
            pageCount: 0,
            selected: 1,
            produtos: [],
            departamentos: []
        }
    };

    componentDidMount() {     
        if (!this.state.produtos){   
            this.buscarProdutos();
        }
    }

    buscarProdutos = async (page, size, sortOrder, sortField, filter) => {
        let complement = "";
        let produtos = [];
        let total = 0;
        let departamentos = [];

        page = page ? page : this.state.selected;
        filter = filter ? filter : {};
        const { match: { params } } = this.props;   
        
        await api.get("/departamentos")         
            .then(function (response) {
                departamentos = response.data.data;                                      
            });
        
        this.setState({departamentos: departamentos});

        let depart_find = buscarDep(this.state.departamentos, params.departamento);        

        if ( !depart_find ) {
            filter = {
                nome: params.departamento
            };

            complement += "&filter=" + encodeURIComponent(JSON.stringify(filter));
            
            await api.get('/produtos?'+complement)
            .then(function (response) {
                produtos = response.data.data; 
                total = response.data.total;                        
            });
            
            this.setState({ produtos: produtos, pageCount: (total/30 > 0 ? total/30 : 1)  });

            return;
        }

        let categ_find = buscarCat(depart_find, params.categoria);
            
        if (categ_find) {
            filter.produtos_categorias_id = categ_find.id;
        }
        else if (depart_find) {
            filter.produtos_departamentos_id = depart_find.id;
        }


        complement += (typeof(page) != "undefined") ? "page=" + page : "";
        complement += "&size=30";
        complement += "&sortOrder=ASC";
        complement += "&sortField=disponivel_venda";
        
        if ( typeof(filter) != "undefined" ) {
            complement += "&filter=" + encodeURIComponent(JSON.stringify(filter));
        }
        
        await api.get('/produtos?'+complement)
        .then(function (response) {
            produtos = response.data.data; 
            total = response.data.total;                        
        });
        
        this.setState({ produtos: produtos, pageCount: (total/30 > 0 ? total/30 : 1)  });
    }

    handlePageClick = (props) => {        
        let param;

        if (props && props.selected) {
            param = ++props.selected;                     
        }

        param = param ? param : 1;

        this.buscarProdutos(param);        
    }
    
    render() {
        return (
            <Container>
                <NavLink departamentos={this.state.departamentos}/>
                <ProductsList produtos={this.state.produtos} handlePageChange={this.handlePageClick} usuario={this.props.usuario} pedido_carrinho={this.props.pedido_carrinho} buscarPedidoCarrinho={this.props.buscarPedidoCarrinho}/>
                <PaginateProduct pageCount={this.state.pageCount} handlePageClick={this.handlePageClick} />
            </Container>
        );
    }
}


export default Products;