import React, {Component} from 'react';
import api from '../../services/api';
import { Container, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import SlideDetail from '../../components/slideDetail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import env from "../../config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';
import VendaModalAddProduto from '../../components/vendaModalAddProduto';

import "./styles.css";

const ValorProduto = (props) => {
    let formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
    });
    
    return (
        <strong>{formatter.format(parseFloat(props.valor).toFixed(2))}</strong>
    );
}

export default class Product extends Component {
    constructor (props) {
        super(props);
        this.state = {
            produto: {
                fotos: []
            },
            show: false
        }
    };

    async componentDidMount() {
        const { match: { params } } = this.props;         
        this.buscarProduto(params.id);    
    }     
    
    buscarProduto = async (id) => {
        let produto={};

        await api.get('/produtos/'+id)
        .then(async (response) => {
            produto = response.data.data;
            
            await api.get('/produtos/'+id+"/fotos")
                .then( (res) => {
                    let fotos = res.data.data;
                    let fotos_estruct = fotos.map((foto,i) => {
                        return {
                            img: env.url_files + foto.id +"-"+ foto.filename,
                            description: produto.nome,
                            original: env.url_files + foto.id +"-"+ foto.filename,
                            thumbnail: env.url_files + foto.id +"-"+ foto.filename
                        }
                    });
                    produto.fotos = fotos_estruct;                                             
                });              
        });
        this.setState({ produto: produto });
    }
        
    handleComprar = async (props) => {
        let text;

        text = "*CONSULTA INFOR*\n"+ this.state.produto.nome + "\nQuantidade:" ;                    

        if (this.props.usuario.id){          
            this.setState({show: true});
        }
        else{
            let url = "https://api.whatsapp.com/send?1=pt_BR&phone=5588998457793&text=" + encodeURIComponent(text) ;
            window.open(url);
        }
    }

    render() {
    
        return (
            <Container className="content-body">
                <Row>
                    <Col xs={12} md={10}>
                        <Row>
                            <Col xs={12} md={8}>                                
                                <SlideDetail fotos={this.state.produto.fotos}/>
                            </Col>
                            <Col xs={12} md={4} className="product-info-wrape">
                                <h1 className="product-name">{this.state.produto.nome}</h1>
                                <h3>{this.state.produto.produtos_marcas_nome}</h3>
                                <p>{this.state.produto.descricao}</p>                                
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col className="product-list-wrape" xs={12} md={12}>
                                {/* <FeaturedProducts title="Mesma Categoria" color="#333" products={products}/> */}
                            </Col>
                        </Row>
                    </Col>
                    <Col  xs={12} md={2} className="content-right">
                        <div className="delivery">                            
                            <FontAwesomeIcon icon={faTruck} className="icon"/>  
                            <span>  
                                Entrega Rápida em Juazeiro do Norte-CE
                            </span>
                        </div>
                        <div className="value">
                            { this.props.usuario == null || this.props.usuario.error &&
                                <>
                                    {this.state.produto.disponivel_venda &&
                                        <>
                                            <span className="tag-varejo">Entre no sistema</span>
                                        </>
                                    }
                                </>
                            }
                            { this.props.usuario && !this.props.usuario.error &&
                                <>
                                    {this.state.produto.disponivel_venda &&
                                        <>
                                            <ValorProduto valor={this.state.produto.preco_atacado} />
                                            <span className="tag-atacado">Atacado</span>
                                        </>
                                    }
                                </>
                            }
                        </div>
                        {this.state.produto.disponivel_venda && this.props.usuario && this.props.usuario.id &&
                            <ButtonToolbar>                                
                                <Button variant="primary" onClick={this.handleComprar}>Comprar Agora</Button>                                
                            </ButtonToolbar>
                        }   
                        {this.state.produto.disponivel_venda && (!this.props.usuario || !this.props.usuario.id) &&
                            <ButtonToolbar>                                
                                <Button variant="primary" onClick={this.handleComprar}>Consultar Preço</Button>                                
                            </ButtonToolbar>
                        }   
                        {!this.state.produto.disponivel_venda &&
                            <span className="tag-falta">Em falta</span>
                        }    
                    </Col>
                </Row>
                <VendaModalAddProduto show={this.state.show} produto={this.state.produto} handleClose={()=>{this.setState({show: false})}} usuario={this.props.usuario} pedido_carrinho={this.props.pedido_carrinho} buscarPedidoCarrinho={this.props.buscarPedidoCarrinho}/>
            <ToastContainer />
            </Container>
        );
    }
}